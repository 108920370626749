
import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Header } from "@features/header/Header";
import { Footer } from "@features/footer/Footer";
import { Popup } from "@features/popup/Popup";
import { SpeedDialButton } from "@components/speed-deal";

export function Layout() {
  const popup = useSelector((state: ApplicationState) => state.reducers.popup);

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
      {popup && <Popup />}
      <SpeedDialButton />
    </>
  );
}

 