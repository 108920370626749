import React from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import { BreadCrumbs } from "@components/breadcrumbs";
import { Services } from "@features/services";
import { Advantages } from "@components/advantages";
import appInfo from "@data/app-info.json";

export function ServicesPage() {
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: { sx: "15vh", lg: "23vh" },
      backgroundColor: theme.palette.primary.main,
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    topContainer: {
      pt: { sm: "30px", lg: "40px" },
    },
    title: {
      color: theme.palette.primary.contrastText,
      marginTop: { sx: "10px", lg: "20px" },
    },
    contentContainer: {
      padding: "60px 10px",
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #323232, transparent)",
      pb: "10px",
    },
    devider: {
      margin: "50px auto",
    },
    advantagesTitle: {
      fontSize: theme.typography.h3,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
      textAlign: "center",
      marginBottom: "20px"
    },
    advantageWrapper: {
      marginTop: "30px",
    },
  };  

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="body1" gutterBottom sx={styles.title}>
              Расценки на работы выполняемые нашей компанией
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Typography variant="body1" sx={styles.advantagesTitle}>
          Основные виды работ
        </Typography>
        <Grid sx={styles.contentContainer}>
          <Services />
        </Grid>
        <Grid sx={styles.contentContainer}>
          <Typography variant="body1" sx={styles.advantagesTitle}>
            Нас рекомендуют потому что "{appInfo.companyName}" это:
          </Typography>
          <Advantages />
        </Grid>
      </Container>
    </>
  );
}
