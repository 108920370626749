import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./styles.css";
import { Button, Grid, Typography, useTheme } from "@mui/material";
import { Verified } from "@mui/icons-material";
import { LazyLoadImageBox } from "@components/lazy-load";
import { quizModalToggleAction } from "@components/quiz";
import { popupModalToggleAction } from "@features/popup";

const images = [
  {
    id: 0,
    name: "slider-top-1.jpg",
    alt: "Слайдер 1",
    title: "Строительство домов под ключ в Севастополе и Крыму",
    text: "Профессиональные строители с многолетним стажем и огромным опытом работы в строительстве домов.",
    subtitle: "Заказывая работу у нас вы получите",
    advantages: [
      { id: 0, title: "3 года гарантии" },
      { id: 1, title: "Высокое качество материалов" },
      { id: 2, title: "Высокое качество работ" },
      { id: 3, title: "Строительство в срок" },
    ],
  },
  {
    id: 1,
    name: "slider-top-2.jpg",
    alt: "Слайдер 2",
    title: "Ремонт квартир под ключ в Севастополе и Крыму",
    text: "Профессиональные строители с многолетним стажем и огромным опытом работы в отелке помещений.",
    subtitle: "Заказывая работу у нас вы получите",
    advantages: [
      { id: 0, title: "3 года гарантии" },
      { id: 1, title: "Высокое качество материалов" },
      { id: 2, title: "Высокое качество работ" },
      { id: 3, title: "Ремонт в срок" },
    ],
  },
];

export function CarouselTop() {
  const popup = useSelector((state: ApplicationState) => state.reducers.popup);
  const dispatch = useDispatch();
  const theme = useTheme();

  const isQuizToggle = () => {
    dispatch(quizModalToggleAction(false));
    dispatch(popupModalToggleAction(!popup));
  };

  const styles = {
    slideBox: {
      display: "flex",
      position: "relative",
      minWidth: "100vw",
      width: "100%",
    },
    contentBox: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      maxWidth: { xs: "100vw", md: "75vw" },
      width: "100%",
      minHeight: "450px",
      heigth: "100%",
      margin: { xs: "0 auto", md: "70px auto" },
      padding: { xs: "30px", sm: "80px 30px", md: "30px 20px" },
      borderRadius: { xs: 0, md: "8px" },
      backgroundColor: "rgba(250, 250, 250, 0.5)",
      [theme.breakpoints.down("md")]: {
        bottom: 0,
      },
    },
    title: {
      fontSize: theme.typography.h3,
      fontWeight: 700,
      color: theme.palette.secondary.main,
    },
    advantageWrapper: {
      maxWidth: { xs: "100%", md: "600px" },
      margin: { xs: "15px auto 0", md: "0px auto", lg: "25px auto" },
    },
    advatageBox: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
        marginLeft: "25px",
      },
    },
    text: {
      fontSize: theme.typography.h6,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      maxWidth: "500px",
      margin: "20px auto 0",
    },
    subtitle: {
      fontSize: theme.typography.h4,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      margin: "20px 0 0",
    },
    advantageItem: {
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      marginTop: { xs: 0, md: 2 },
    },
    advantageIcon: {
      color: theme.palette.primary.main,
      marginRight: "8px",
    },
    btnBox: {
      display: "flex",
      justifyContent: { xs: "center", md: "right" },
      alignItems: "bottom",
    },
    sliderButton: {
      marginTop: "auto",
    },
  };

  return (
    <Swiper
      loop={true}
      spaceBetween={10}
      navigation={true}
      modules={[FreeMode, Navigation]}
      className="mySwiperTop"
      autoplay={true}
    >
      {images.map((item: any) => {
        return (
          <Grid sx={styles.slideBox} height={"100%"} key={item.id}>
            <SwiperSlide>
              <Grid sx={styles.slideBox} height={"100%"}>
                <LazyLoadImageBox
                  img={item}
                  type={"top-slider"}
                  height="100%"
                />
                <Grid sx={styles.contentBox}>
                  <Typography variant="body1" sx={styles.title}>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" sx={styles.text}>
                    {item.text}
                  </Typography>
                  <Typography variant="body1" sx={styles.subtitle}>
                    {item.subtitle}
                  </Typography>
                  <Grid container sx={styles.advantageWrapper}>
                    <Grid item xs={12} md={6} sx={styles.advatageBox}>
                      {item.advantages.map((advantage: any) => {
                        return (
                          <Typography
                            variant="body1"
                            key={advantage.id}
                            sx={styles.advantageItem}
                          >
                            <Verified sx={styles.advantageIcon} />
                            {advantage.title}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={12} md={6} sx={styles.btnBox}>
                      <Button
                        onClick={isQuizToggle}
                        color="primary"
                        variant="contained"
                        sx={styles.sliderButton}
                      >
                        Заказать звонок
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SwiperSlide>
          </Grid>
        );
      })}
    </Swiper>
  );
}
