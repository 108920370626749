import React, {useState} from "react";
import {Backdrop, Box, Fade, SpeedDial, SpeedDialAction, useTheme} from "@mui/material";
import { Avatar, Link, styled, useScrollTrigger } from "@mui/material";
import {
  Calculate,
  MarkUnreadChatAlt,
  Phone,
  Telegram,
  WhatsApp,
} from "@mui/icons-material";
import appInfo from "@data/app-info.json";
import { useDispatch, useSelector } from "react-redux";
import { popupModalToggleAction } from "@features/popup";
import { quizModalToggleAction } from "@components/quiz";

const StyledLink = styled(Link)({
  display: "flex",
  alignItems: "center",
});

interface Props {
  window?: () => Window;
}

export function SpeedDialButton(props: Props) {
  const { window } = props;
  const [open, setOpen] = useState(false);
  const popup = useSelector((state: ApplicationState) => state.reducers.popup);
  
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);  

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 200,
  });

  const isCallBackToggle = () => {
    dispatch(quizModalToggleAction(false));
    dispatch(popupModalToggleAction(!popup));
  };

  return (
    <>
      <Backdrop open={open} />
      <Fade in={trigger}>
        <Box
          sx={{
            height: "56px",
            width: "56px",
            bottom: 0,
            right: 0,
            transform: "translateZ(0px)",
            flexGrow: 1,
            position: "fixed",
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: "absolute", bottom: 12, right: 12 }}
            icon={<MarkUnreadChatAlt />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
          >
            <SpeedDialAction
              icon={
                <StyledLink
                  onClick={isCallBackToggle}
                  color={theme.palette.primary.main}
                >
                  <Phone />
                </StyledLink>
              }
              tooltipTitle={"Заказать звонок"}
              tooltipOpen
              onClick={handleClose}
            />
            <SpeedDialAction
              icon={
                <Avatar sx={{ bgcolor: "#43d854" }}>
                  <StyledLink
                    href={`https://wa.me/${appInfo.contacts.messengers[2].whatsApp}`}
                    color={"#fff"}
                  >
                    <WhatsApp />
                  </StyledLink>
                </Avatar>
              }
              tooltipTitle={"WhatsApp"}
              tooltipOpen
              onClick={handleClose}
            />
            <SpeedDialAction
              icon={
                <Avatar sx={{ bgcolor: "#0088cc" }}>
                  <StyledLink
                    href={`http://t.me/${appInfo.contacts.messengers[1].name}`}
                    color={"#fff"}
                  >
                    <Telegram />
                  </StyledLink>
                </Avatar>
              }
              tooltipTitle={"Telegram"}
              tooltipOpen
              onClick={handleClose}
            />
            <SpeedDialAction
              icon={
                <Avatar sx={{ bgcolor: "#7360F2" }}>
                  <StyledLink
                    href={`viber://chat?number=%2B${appInfo.contacts.messengers[3].viber}`}
                    color={"#fff"}
                  >
                    <Phone />
                  </StyledLink>
                </Avatar>
              }
              tooltipTitle={"Viber"}
              tooltipOpen
              onClick={handleClose}
            />
            <SpeedDialAction
              icon={
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <StyledLink
                    href={`tel:${appInfo.contacts.messengers[0].phone}`}
                    color={"#fff"}
                  >
                    <Phone />
                  </StyledLink>
                </Avatar>
              }
              tooltipTitle={"Телефон"}
              tooltipOpen
              onClick={handleClose}
            />
          </SpeedDial>
        </Box>
      </Fade>
    </>
  );
}
