import React, { useState } from "react";
import {
  Box,
  Grid,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function createData(
  work: string,
  unit: string | null,
  price: number | null,
  workList: any
) {
  return {
    work,
    unit,
    price,
    history: workList,
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const styles = {
    row: {
      "& > *": { borderBottom: "unset" },
      backgroundColor: "#f1f1f1",
      boxShadow: "0 8px 8px rgba(0,0,0,0.25), 0 8px 8px rgba(0,0,0,0.22)",
      cursor: "pointer"
    },
    wrapCell: { paddingBottom: 0, paddingTop: 0 },
    headCell: {
      padding: { xs: "5px", sm: "8px" },
      fontWeight: 700,
    },
    headUnitCell: {
      padding: { xs: "5px", sm: "8px" },
      fontSize: { xs: "0.8rem", sm: "0.9rem" },
      fontWeight: 700,
    },
    dataCell: {
      padding: { xs: "5px", sm: "8px" },
      fontSize: { xs: "0.8rem", sm: "0.9rem" },
    },
    unitCell: {
      padding: { xs: "5px", sm: "8px" },
      fontSize: { xs: "0.7rem", sm: "0.9rem" },
    },
  };

  return (
    <>
      <TableRow sx={styles.row} onClick={() => setOpen(!open)}>
        <TableCell width={32}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
          {row.work}
        </TableCell>
        <TableCell align="center">{row.unit}</TableCell>
        <TableCell align="right">{row.price}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={styles.wrapCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.headCell}>
                      Наименование работ
                    </TableCell>
                    <TableCell sx={styles.headUnitCell} align="center">
                      Ед.изм.
                    </TableCell>
                    <TableCell sx={styles.headCell} align="right">
                      Цена
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow: any) => (
                    <TableRow key={historyRow.work}>
                      <TableCell sx={styles.dataCell}>
                        {historyRow.work}
                      </TableCell>
                      <TableCell align="center" sx={styles.unitCell}>
                        {historyRow.unit}
                      </TableCell>
                      <TableCell align="right" sx={styles.dataCell}>
                        {historyRow.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function PriceTable(props: Price.Data) {
  const { data } = props;
  const theme = useTheme();

  const rows = data.subgroups.map((item) => {
    return createData(item.subgroup, null, null, item.works);
  });

  const styles = {
    tableTitle: {
      color: theme.palette.secondary.main,
      textAlign: "center",
      margin: "30px auto",
    },
    paper: {
      maxWidth: "900px",
      width: "100%",
      padding: "5px",
      margin: "0 auto",
      backgroundColor: theme.palette.primary.light,
    },
  };

  return (
    <Grid>
      <Typography variant="h2" sx={styles.tableTitle}>
        {data.group}
      </Typography>
      <Paper sx={styles.paper}>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableBody>
              {rows.map((row) => (
                <Row key={row.work} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
}
