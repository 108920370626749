export function telegramBot(props: any) {
  const url = '/php/bot.php';
  const { customer } = props;  

  const settings = {
    async: true,
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
    },
    mode: "cors" as RequestMode,
    body: JSON.stringify({
      name: customer.customerName,
      phone: customer.customerPhone,
    }),
  };
  fetch(url, settings)
    .then(function (response) {
      return response.json();
    });
}
