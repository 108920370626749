import { combineReducers, createAction, createReducer } from "@reduxjs/toolkit";

//action factory
const actionFactory = (() =>
  function <P = void>(key: string) {
    return createAction<P>(`@@feature/lightbox/${key}`);
  })();

// actions
export const lightBoxModalToggleAction = actionFactory<boolean>(
  "is-open"
);
export const lightBoxImgIdAction = actionFactory<number | null>("id");

// reducers
export const lightBoxModalToggleReducer = createReducer<boolean>(false, (b) => {
  b.addCase(lightBoxModalToggleAction, (state, action) => action.payload);
});

export const lightBoxImgIdReducer = createReducer<number | null>(null, (b) => {
  b.addCase(lightBoxImgIdAction, (state, action) => action.payload);
});

export const lightBoxReducer = combineReducers({
  isOpen: lightBoxModalToggleReducer,
  clickedId: lightBoxImgIdReducer,
});