import React from "react";
import { Avatar, Link, Stack, styled } from "@mui/material";
import { Phone, Email, Telegram, WhatsApp } from "@mui/icons-material";
import { orange } from "@mui/material/colors";
import appInfo from "@data/app-info.json"

const StyledLink = styled(Link)({
  display: "flex",
  alignItems: "center",
})

export function Messengers() {
  return (
    <Stack direction="row" spacing={2}>
      <Avatar sx={{ bgcolor: "#0088cc" }}>
        <StyledLink
          href={`http://t.me/${appInfo.contacts.messengers[1].name}`}
          color={"#fff"}
        >
          <Telegram />
        </StyledLink>
      </Avatar>
      <Avatar sx={{ bgcolor: "#43d854" }}>
        <StyledLink
          href={`https://wa.me/${appInfo.contacts.messengers[2].whatsApp}`}
          color={"#fff"}
        >
          <WhatsApp />
        </StyledLink>
      </Avatar>
      <Avatar sx={{ bgcolor: orange[500] }}>
        <StyledLink
          type="mail"
          href={`mailto:${appInfo.contacts.messengers[4].email}`}
          color={"#fff"}
        >
          <Email />
        </StyledLink>
      </Avatar>
      <Avatar sx={{ bgcolor: "#7360F2" }}>
        <StyledLink
          href={`viber://chat?number=%2B${appInfo.contacts.messengers[3].viber}`}
          color={"#fff"}
        >
          <Phone />
        </StyledLink>
      </Avatar>
    </Stack>
  );
}
