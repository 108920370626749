import React from "react";
import { YMaps, Map, Placemark, ZoomControl } from "@pbe/react-yandex-maps";
import { Grid, Link, Typography, useTheme } from "@mui/material";
import { Email, Place, Phone } from "@mui/icons-material";
import { Messengers } from "@components/messengers";
import appInfo from "@data/app-info.json";
import "./styles.css";

export const ContactsInfo = () => {
  const theme = useTheme();

  const styles = {
    title: {
      fontSize: theme.typography.h4,
      fontWeight: 700,
      color: theme.palette.text.primary,
      margin: "20px 0",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    contsctsTitle: {
      fontSize: theme.typography.h2,
      fontWeight: 700,
      color: theme.palette.text.primary,
      margin: "20px 0",
    },
    subTitle: {
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
    adress: {
      fontSize: theme.typography.h6,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      mb: { xs: "15px", md: "30px" },
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
      },
      maxWidth: { xs: "100%", md: "320px" },
    },
    link: {
      fontSize: theme.typography.h5,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      mb: "15px",
    },
    mark: {
      marginRight: "10px",
      color: theme.palette.primary.dark,
    },
    phoneLink: {
      fontSize: theme.typography.h5,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      mb: "15px",
      ml: "10px",
    },
    workTime: {
      display: "flex",
      alignItems: "center",
      fontSize: "10px",
      color: theme.palette.text.primary,
    },
    greenDot: {
      display: "flex",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#25d266",
      marginRight: "8px",
      animation: "glowing 1s infinite",
    },
    messengersBox: {
      display: "flex",
      width: "100%",
      marginTop: "20px",
    },
  };

  const defaultState = {
    center: [44.583242, 33.436256],
    zoom: 15,
    controls: [],
    width: "100%",
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" sx={styles.title} gutterBottom>
          Ждем вас у нас в офисе по адресу:
        </Typography>
        <Typography variant="body1" gutterBottom sx={styles.adress}>
          <Place sx={styles.mark} fontSize="large" />
          {appInfo.contacts.adress}
        </Typography>
        {/* <Typography variant="body1" sx={styles.subTitle} gutterBottom>
          Email
        </Typography> */}
        <Link
          type="mailto"
          href={"mailto:" + appInfo.contacts.messengers[3].email}
          sx={styles.link}
        >
          <Email sx={styles.mark} fontSize="large" />
          {appInfo.contacts.messengers[4].email}
        </Link>
        {/* <Typography variant="body1" sx={styles.subTitle} gutterBottom>
          Телефон
        </Typography> */}
        <Link
          type="tel"
          href={"tel:" + appInfo.contacts.messengers[0].phone}
          sx={styles.link}
        >
          <Phone sx={styles.mark} fontSize="large" />
          <Grid>
            <Typography variant="body1" sx={styles.workTime}>
              <Typography
                variant="body1"
                component="span"
                sx={styles.greenDot}
              ></Typography>
              Без выходных: 9:00-18:00
            </Typography>
            <Typography variant="body1" sx={styles.phoneLink}>
              {appInfo.contacts.messengers[0].title}
            </Typography>
          </Grid>
        </Link>
        <Grid style={styles.messengersBox}>
          <Messengers />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" sx={styles.title} gutterBottom>
          Мы на карте
        </Typography>
        <YMaps query={{ lang: "ru_RU" }}>
          <Map defaultState={defaultState} width="100%" height={300}>
            <Placemark geometry={[44.583242, 33.436256]} />
            <ZoomControl />
          </Map>
        </YMaps>
      </Grid>
    </Grid>
  );
};
