import React, { SyntheticEvent, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : grey[200],
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

type InfoTabPanelType = {
  info: InfoTabs.Info[]
}

function InfoTabPanel(props: InfoTabPanelType) {
  const { info } = props;
  return (
    <Grid>
      {info.map((type: InfoTabs.Info) => {
        return (
          <Typography
            variant="body1"
            key={type.id}
            textAlign="justify"
            sx={{ marginBottom: "10px" }}
          >
            {type.text}
          </Typography>
        );
      })}
    </Grid>
  );
}

export function InfoTabsMobile(props: InfoTabs.Data) {
  const { data } = props;
  const [expanded, setExpanded] = useState<number | null>(0);

  const styles = {
    question: {
      fontWeight: 700,
    },
  };

  const handleChange =
    (panel: number) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    };

  const accordionItem = data.subtypes.map((item) => {
    return (
      <Accordion
        expanded={expanded === item.id}
        onChange={handleChange(item.id)}
        key={item.id}
      >
        <AccordionSummary
          aria-controls={`panel${item.id}-content`}
          id={`panel${item.id}-header`}
        >
          <Typography sx={styles.question}>{item.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InfoTabPanel info={item.info} />
        </AccordionDetails>
      </Accordion>
    );
  });

  return <Grid>{accordionItem}</Grid>;
}
