import validate from "validate.js";

export function validateForm(values: any) {
  const lengthName = {
    minimum: 2,
    maximum: 250,
    message: function (values: any) {
      return validate.format(
        "^%{num} не может быть именем. Имя должно содержать минимум 2 знака",
        {
          num: values,
        }
      );
    },
  };
  const lengthPhone = {
    minimum: 11,
    maximum: 11,
    message: function (values: any) {
      return validate.format(
        "^%{num} не может быть номером телефона. Перепроверьте вводимый номер",
        {
          num: values,
        }
      );
    },
  };
  const presence = {
    type: "string",
    message: () => {
      return validate.format("^%{num} не может быть пустым", {
        num: "Поле",
      });
    },
  };

  const validationRules = {
    customerName: { presence, length: lengthName },
    customerPhone: { presence, length: lengthPhone },
  };

  return validate(values, validationRules);
}
