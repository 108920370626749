import React from "react";
import { Box, Grid, Container, Typography, useTheme } from "@mui/material";
import { BreadCrumbs } from "@components/breadcrumbs";
import { InfoTabs, InfoTabsMobile } from "@components/info-tabs";
import { Advantages } from "@components/advantages";
import { PriceTable } from "@components/price";
import appInfo from "@data/app-info.json";
import infoTabs from "@data/info-tabs.json";
import price from "@data/price.json";

export function ElectricalPage() {
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: { sx: "15vh", lg: "23vh" },
      backgroundColor: theme.palette.primary.main,
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    topContainer: {
      pt: { sm: "30px", lg: "40px" },
    },
    title: {
      color: theme.palette.primary.contrastText,
      marginTop: { sx: "10px", lg: "20px" },
    },
    contentContainer: {
      padding: { xs: "30px 10px", md: "60px 10px" },
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #323232, transparent)",
      pb: "10px",
    },
    devider: {
      margin: "50px auto",
    },
    advantagesTitle: {
      fontSize: theme.typography.h3,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
      textAlign: "center",
      marginBottom: "20px",
    },
    advantageWrapper: {
      marginTop: "30px",
    },
    topPageTitle: {
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textAlign: "center",
      marginBottom: "30px",
    },
    topPageBox: {
      padding: { xs: 0, md: "50px 10px" },
    },
    topPageImgBox: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    topPageImg: {
      width: "100%",
      height: { xs: "300px", md: "100%" },
      background:
        "url(/static/services/services-icon-4.jpg) no-repeat center center",
      backgroundSize: "cover",
    },
    topPageContent: { marginBottom: "20px" },
    topPageText: { textAlign: "justify", marginBottom: "10px" },
    infoTabsBox: {
      display: { xs: "none", sm: "block" },
      width: { xs: 0, sm: "100%" },
      height: { xs: 0, sm: "100%" },
    },
    infoTabsMobileBox: {
      display: { xs: "block", sm: "none" },
      width: { xs: "100%", sm: 0 },
      height: { xs: "100%", sm: 0 },
    },
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="body1" gutterBottom sx={styles.title}>
              Электромонтажные работы
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Typography variant="h3" sx={styles.topPageTitle}>
          Электромонтажные работы в Севастополе и Крыму
        </Typography>
        <Grid container spacing={2} sx={styles.topPageBox}>
          <Grid item xs={12} md={6} sx={styles.topPageImgBox}>
            <Grid sx={styles.topPageImg}></Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.topPageContent}>
            <Typography variant="body1" sx={styles.topPageText}>
              Электромонтаж — это метод образования соединений (электрических
              цепей) из изделий или их частей, содержащих токоведущие элементы,
              непосредственно на месте использования. Электромонтажные работы
              ведутся поэтапно. Компания {appInfo.companyName} - это гарантия
              качественного выполнения электромонтажных работ. В первую очередь
              для качестенного энергоснабжения дома нужно спланировать и
              спроектировать энергопотребление дома.
            </Typography>
            <Typography variant="body1" sx={styles.topPageText}>
              Современный электромонтаж – это всегда работа группы
              профессионалов, и его всегда адаптируют к индивидуальным
              потребностям заказчика.
            </Typography>
            <Typography variant="body1" sx={styles.topPageText}>
              С нами надежно и выгодно! Большое внимание мы уделяем качеству
              материалов и надежности конструкций. Но при этом закупки у
              постоянных поставщиков и собственное производство позволяют
              сдерживать цены на строительство домов под ключ на привлекательном
              уровне.
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h3" sx={styles.topPageTitle}>
          Электромонтажные работы в Крыму и Севастополе
        </Typography>
        <Box sx={styles.infoTabsBox}>
          <InfoTabs data={infoTabs.items[2]} />
        </Box>
        <Box sx={styles.infoTabsMobileBox}>
          <InfoTabsMobile data={infoTabs.items[2]} />
        </Box>
        <PriceTable data={price.items[2]} />
        <Grid sx={styles.contentContainer}>
          <Typography variant="body1" sx={styles.advantagesTitle}>
            Нас рекомендуют потому что "{appInfo.companyName}" это:
          </Typography>
          <Advantages />
        </Grid>
      </Container>
    </>
  );
}
