import { combineReducers, createAction, createReducer as reducer } from "@reduxjs/toolkit";

// types
type SettingsState = {
  pageSize: number;
  pageCount: number;
  pageIndex: number;
};

//action factory
const actionFactory = (() =>
  function <P = void>(key: string) {
    return createAction<P>(`@@features/services/${key}`);
  })();

const settingsState: SettingsState = {
  pageSize: 9,
  pageCount: 1,
  pageIndex: 1,
};

const services: Services.ItemsTypes = [];

export type ChangeSettingsArgs = Partial<SettingsState>;

//actions
export const changeSettings = actionFactory<ChangeSettingsArgs>("settings/change");

export const fetchServicesList = actionFactory<Services.ItemsTypes>("list");

//reducers
const settingsReducer = reducer(settingsState, (b) => {
  b.addCase(changeSettings, (state, { payload }) => ({
    ...state,
    ...payload,
  }));
});

const fetchServicesListReducer = reducer(services, (b) => {
  b.addCase(fetchServicesList, (_s, { payload }) =>
    payload.map((item) => ({ ...item }))
  );
});


export const ServicesReducer = combineReducers({
  servicesList: fetchServicesListReducer,
  settings: settingsReducer,
});
