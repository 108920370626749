import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Link, Typography, useTheme } from "@mui/material";
import { BreadCrumbs } from "@components/breadcrumbs";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

export function NotFoundPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: { sx: "15vh", lg: "23vh" },
      backgroundColor: theme.palette.primary.main,
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    topContainer: {
      pt: { sm: "30px", lg: "40px" },
    },
    title: {
      color: theme.palette.primary.contrastText,
      marginTop: { sx: "10px", lg: "20px" },
    },
    contentContainer: {
      padding: "60px 10px",
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #323232, transparent)",
      pb: "10px",
    },
    devider: {
      margin: "50px auto",
    },
    advantagesTitle: {
      fontSize: theme.typography.h3,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
      textAlign: "center",
      marginBottom: "20px",
    },
    advantageWrapper: {
      marginTop: "30px",
    },
    message: {
      display: "flex",
      alignItems: "center",
    },
  };  

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="h2" gutterBottom sx={styles.title}>
              Ошибка 404{" "}
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Typography variant="h5" sx={styles.message}>
          Ой! Страница не найдена.{" "}
          <SentimentVeryDissatisfiedIcon fontSize="large" />
        </Typography>
        <Link onClick={() => navigate(-1)} className={"active-nav-link"}>
          Вернуться назад
        </Link>
      </Container>
    </>
  );
}
