import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function ProgressLine(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{height: 15, borderRadius: 4}} />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box> */}
    </Box>
  );
}

export function ProgressInfoBar(props: any) {
  const { length, activeQuestion } = props;
  const progress = (100 / length) * (activeQuestion);
    
  return (
    <Box sx={{ width: "100%", mt: "15px" }}>
      <ProgressLine value={progress} />
    </Box>
  );
}
