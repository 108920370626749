import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Carousel } from "@components/carousel";

export function ImageViewer({ portfolio }: any) {
  const theme = useTheme();
  const styles = {
    title: {
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textAlign: "center",
      margin: "30px auto",
    },
  };
  
  return (
    <Box>
      <Typography variant="h3" sx={styles.title}>
        Посмотрите примеры работ, выполняемых нашей компанией
      </Typography>
      <Carousel
        images={portfolio.images}
        isThumbs={false}
        isLightBox
        type="portfolio"
        height={280}
        works={portfolio.type}
        className={"image-viewer-slider"}
      />
    </Box>
  );
}
