import React, { useRef, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  Button,
  Card,
  CardMedia,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { ProgressInfoBar } from "./ProgressInfoBar";
import { popupModalToggleAction } from "@features/popup";
import quiz from "@data/quiz.json";
import { telegramBot } from "@components/telegramBot/telegramBot";
import { validateForm } from "@components/validate-form";

export const Quiz = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const open = useSelector((state: ApplicationState) => state.reducers.popup);
  const [value, setValue] = useState("");
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [answers, setAnswers] = useState<any>([]);
  const dispatch = useDispatch();
  const theme = useTheme();

  const styles = {
    title: {
      textAlign: "center",
      fontSize: theme.typography.h3,
      fontWeight: 700,
      mb: "10px",
    },
    subTitle: {
      fontSize: theme.typography.h4,
      textAlign: "center",
      display: "block",
    },
    question: {
      textAlign: "center",
      display: "flex",
      fontWeight: 700,
      mt: "15px",
      justifyContent: "center",
      color: theme.palette.primary.dark,
    },
    card: {
      maxWidth: "calc(50% - 5px)",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    formControl: {
      width: "100%",
    },
    radioGroup: {
      mt: "15px",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    radio: {
      p: "0 10px",
    },
    button: {
      display: "flex",
      margin: "20px auto 0",
    },
  };

  const currentQuestion = quiz.questions.find(
    (item) => item.question_id === activeQuestion
  );
  const quizLength = quiz.questions.length;
  const lastQuestion = activeQuestion === quizLength ?? true;

  const onAnswerClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const curentValue = (event.target as HTMLInputElement).value;

    setValue(curentValue);

    const curentId = () => {
      const curentAnswer = currentQuestion
        ? quiz.questions[currentQuestion.question_id].answers.find(
            (item) => item.text === curentValue
          )
        : quiz.questions[0].answers.find((item) => item.text === curentValue);
      return curentAnswer ? curentAnswer.answer_id : 0;
    };

    setAnswers([
      ...answers,
      {
        question: quiz.questions[activeQuestion].question,
        answer: quiz.questions[activeQuestion].answers[curentId()].text,
      },
    ]);

    setTimeout(() => {
      if (!lastQuestion) {
        setActiveQuestion(activeQuestion + 1);
      }
    }, 500);
  };

  const onSubmit = (values: any) => {
    const newValues = {
      customer: values,
      quizData: answers,
    };
    telegramBot(newValues);
    setIsSuccess(!isSuccess);
    setTimeout(() => {
      dispatch(popupModalToggleAction(!open));
    }, 5000);
  };

  const focusRef = useRef<HTMLInputElement>(null);
  const unfocusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    focusRef.current?.focus();
  }, [activeQuestion, lastQuestion]);

  return (
    <>
      {!isSuccess && (
        <>
          {!lastQuestion && (
            <>
              <Typography variant="body1" sx={styles.title}>
                Пройдите короткий тест
              </Typography>
              <Typography variant="body1" sx={styles.subTitle}>
                и получите предварительный расчет стоимости дома
              </Typography>
              <ProgressInfoBar
                length={quizLength}
                activeQuestion={activeQuestion}
              />
            </>
          )}
          <FormControl sx={styles.formControl}>
            <FormLabel
              id={
                currentQuestion
                  ? `quiz-label-${currentQuestion.question}`
                  : "quiz-label"
              }
              sx={styles.question}
            >
              {currentQuestion?.question}
            </FormLabel>
            <Form
              onSubmit={onSubmit}
              validate={validateForm}
              initialValues={{}}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  {!lastQuestion && (
                    <Field
                      name={
                        currentQuestion
                          ? `quiz-field-${currentQuestion.question}`
                          : "quiz-field"
                      }
                      type="radio"
                      render={({ input }) => (
                        <RadioGroup
                          {...input}
                          aria-labelledby="quiz"
                          name={
                            currentQuestion
                              ? `quiz-radio-group${currentQuestion.question}`
                              : "quiz-radio-group"
                          }
                          value={value}
                          onChange={onAnswerClick}
                          sx={styles.radioGroup}
                        >
                          {currentQuestion &&
                            currentQuestion.answers.map((answer: any) => {
                              return (
                                <Card key={answer.answer_id} sx={styles.card}>
                                  {answer.img && (
                                    <CardMedia
                                      component="img"
                                      image={`/static/quiz/${answer.img}`}
                                      alt={answer.text}
                                      height="150"
                                    />
                                  )}
                                  <FormControlLabel
                                    value={answer.text}
                                    control={<Radio />}
                                    label={answer.text}
                                    sx={styles.radio}
                                    ref={
                                      answer.text ===
                                      currentQuestion.answers[0].text
                                        ? focusRef
                                        : unfocusRef
                                    }
                                  />
                                </Card>
                              );
                            })}
                        </RadioGroup>
                      )}
                    />
                  )}
                  {lastQuestion && (
                    <>
                      <FormLabel id="contact" sx={styles.question}>
                        Укажите куда выслать расчет стоимости строительства
                      </FormLabel>
                      <div style={{ marginTop: 15 }}>
                        <Field
                          name="contact"
                          type="radio"
                          render={({ input }) => (
                            <RadioGroup
                              {...input}
                              row
                              defaultValue="Телефон"
                              aria-label="Контакты"
                              name="contact-radio-group"
                            >
                              <FormControlLabel
                                value="Telegram"
                                control={<Radio />}
                                label="Telegram"
                                ref={focusRef}
                              />
                              <FormControlLabel
                                value="WhatsApp"
                                control={<Radio />}
                                label="WhatsApp"
                              />
                              <FormControlLabel
                                value="Viber"
                                control={<Radio />}
                                label="Viber"
                              />
                              <FormControlLabel
                                value="Телефон"
                                control={<Radio />}
                                label="Телефон"
                              />
                            </RadioGroup>
                          )}
                        />
                      </div>
                      <Field
                        name="customerName"
                        render={({ input, meta }) => (
                          <TextField
                            {...input}
                            fullWidth
                            label={"Введите имя"}
                            error={
                              meta.touched && (meta.error || meta.submitError)
                            }
                            helperText={
                              meta.touched && (meta.error || meta.submitError)
                            }
                            style={{ marginTop: 15 }}
                            sx={{ marginBottom: "15px" }}
                          />
                        )}
                      />
                      <Field
                        name="customerPhone"
                        render={({ input, meta }) => (
                          <>
                            <PhoneInput
                              {...input}
                              country={"ru"}
                              specialLabel={"Введите номер телефона"}
                              inputProps={{
                                name: "phone",
                                required: true,
                              }}
                              inputStyle={
                                meta.touched && (meta.error || meta.submitError)
                                  ? { borderColor: "#d32f2f", width: "100%" }
                                  : { width: "100%" }
                              }
                              dropdownStyle={{
                                position: "fixed",
                                top: 76,
                                left: 0,
                                right: 0,
                                margin: "0 auto",
                                maxHeight: "70vh",
                              }}
                            />
                            {meta.touched && (
                              <Typography
                                variant="body2"
                                style={{
                                  color: "#d32f2f",
                                  fontSize: "0.75rem",
                                  margin: "3px 14px 0 14px",
                                }}
                              >
                                {meta.error || meta.submitError}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                      <Button
                        type="submit"
                        disabled={submitting}
                        color="secondary"
                        variant="contained"
                        sx={styles.button}
                      >
                        Получить расчет и подарок
                      </Button>
                    </>
                  )}
                </form>
              )}
            />
          </FormControl>
        </>
      )}

      {isSuccess && (
        <div
          style={{
            fontSize: 24,
            fontWeight: 700,
            color: theme.palette.primary.main,
            marginTop: 15,
          }}
        >
          Информация передана менеджеру.
          <br />В ближайшее время, с Вами свяжутся
        </div>
      )}
    </>
  );
};
